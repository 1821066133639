import Button from '@/components/core/Button'
import Typography from '@/components/core/Typography'
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon'
import ArrowRightIcon from '@/components/icons/ArrowRightIcon'
import { SLIDER_NEXT, SLIDER_PREV } from '../../types'
import styles from './SliderButtons.module.scss'

type SliderButtonsType = {
  onChange: (param: string) => void
  current: number
  total: number
}

const SliderButtons = ({ onChange, current, total }: SliderButtonsType) => (
  <div className={styles.slider_buttons}>
    <Button
      variant="ghost"
      disabled={current < 1}
      onClick={() => onChange(SLIDER_PREV)}
      className={styles.button}
      data-testid="button-prev"
    >
      <ArrowLeftIcon />
    </Button>
    <Typography as="span">
      {current + 1} of {total}
    </Typography>
    <Button
      variant="ghost"
      disabled={current + 1 === total}
      onClick={() => onChange(SLIDER_NEXT)}
      className={styles.button}
      data-testid="button-next"
    >
      <ArrowRightIcon />
    </Button>
  </div>
)
export default SliderButtons
