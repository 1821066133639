import { StaticImageData } from 'next/image'

export type TestimonialType = {
  text: string
  name: string
  title: string
  image?: StaticImageData
  company: string
  profileSlug: string
}

export type TestimonialsType = {
  title: string
  testimonials: TestimonialType[]
}

export const SLIDER_NEXT = 'slider_next'
export const SLIDER_PREV = 'slider_prev'
export const MIN_DESKTOP_WIDTH = 1024
