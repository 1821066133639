'use client'
import CollapsableItem from '@/components/core/CollapsibleItem'
import Link from '@/components/core/Link'
import Typography from '@/components/core/Typography'
import MinusIcon from '@/components/icons/MinusIcon'
import PlusIcon from '@/components/icons/PlusIcon'
import appStoreSrc from '@/public/img/app_store.webp'
import playStoreSrc from '@/public/img/play_store.webp'
import qrSrc from '@/public/img/qr-prod.webp'
import clsx from 'clsx'
import Image from 'next/image'
import styles from './FrequentlyAskedQuestions.module.scss'

const faqs = [
  {
    title: 'How does the Peer Community work?',
    text: 'The Peer Community is a give-to-get system. Members earn points by participating in Surveys, Q&As and Quick Polls. Your points can be used to post questions (for 100 points) or Quick Polls (for 300 points), and they can also be redeemed for a variety of Peer Community Gift Cards.',
  },
  {
    title: "I'm not a Gartner client. Can I join the community?",
    text: 'Yes! Gartner Peer Community platform remains free to join and free to use. Users go through a verification process and need to adhere to community guidelines. Gartner clients may have access to additional, premium features such as published research and insights based on the data, interactive tools, and integration into their overall Gartner.com portal experience.',
  },
  {
    title: 'Why does my account need to be verified?',
    text: 'We verify users to ensure that every user knows who they are engaging with at all times, and knows where the information gathered is coming from. Verification helps to maintain community quality and the production of high-quality knowledge sharing that members can trust. Verified members are identified by the blue checkmark next to their name. In any poll, users can click on the participant count and see which verified technology leaders have responded.',
  },
]

type FrequentlyAskedQuestionsProps = {
  className?: string
}

const FrequentlyAskedQuestions = ({ className }: FrequentlyAskedQuestionsProps) => (
  <section className={clsx(styles.faqs, className)}>
    <div className={styles.faqs_content}>
      <Typography as="h2" variant="h2" className={styles.faqs_title}>
        Frequently Asked Questions
      </Typography>
      {faqs.map(({ title, text }, idx) => (
        <CollapsableItem
          key={`faq-${idx}`}
          className={styles.faqs_item}
          header={
            <div className={styles.faqs_item_header}>
              <Typography as="h3" variant="h4">
                {title}
              </Typography>
              <PlusIcon />
            </div>
          }
          headerExpanded={
            <div className={styles.faqs_item_header}>
              <Typography as="h3" variant="h4">
                {title}
              </Typography>
              <MinusIcon />
            </div>
          }
        >
          <Typography as="p" variant="paragraph" className={styles.faqs_item_content}>
            {text}
          </Typography>
        </CollapsableItem>
      ))}
    </div>
    <div className={styles.faqs_apps}>
      <Typography variant="h5" as="h4" className={styles.dobleSpace}>
        Take Your Insights On-the-Go!
      </Typography>
      <div className={styles.apps}>
        <div className={styles.qrCode}>
          <Image src={qrSrc} alt="QR code" width="103" height="103" unoptimized />
        </div>
        <div className={styles.storeLogos}>
          <Link
            target="_blank"
            href="https://apps.apple.com/us/app/pulse-q-a/id1440075320"
            aria-label="Go to the Apple's Appstore"
          >
            <Image src={appStoreSrc} alt="App store" width="135" height="40" unoptimized />
          </Link>
          <Link
            target="_blank"
            href="https://play.google.com/store/apps/details?id=qa.pulse.app"
            aria-label="Go to the Google's Playstore"
          >
            <Image src={playStoreSrc} alt="Play store" width="180" height="53" unoptimized />
          </Link>
        </div>
      </div>
    </div>
  </section>
)

export default FrequentlyAskedQuestions
