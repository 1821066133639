type ArrowRightIconProps = {
  size?: number
}

const ArrowRightIcon = ({ size = 24 }: ArrowRightIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width={size}
    height="100%"
  >
    <title>arrow right icon</title>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M10.02 6 8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
  </svg>
)

export default ArrowRightIcon
